import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/alanfung/Documents/Projects/Code/gatsby-portfolio/src/templates/journal.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`TL;DR`}</h2>
    <p>{`Gatsby is a static site generator powered by React. You can get a website built with SEO, Google Analytics, and a progressive web app in a matter of minutes. All the prerequisites you need is a little comfort using the terminal and general knowledge of html and css.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1600px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "62.5%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAEDAgX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHryqzYg//EABkQAAIDAQAAAAAAAAAAAAAAAAECABESIP/aAAgBAQABBQJnAO0lcf/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABgQAAIDAAAAAAAAAAAAAAAAAAABESAy/9oACAEBAAY/AoZqv//EABoQAAICAwAAAAAAAAAAAAAAAAERABBBUWH/2gAIAQEAAT8hBXM8g06ZdOf/2gAMAwEAAgADAAAAEGPP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAGhABAAMBAQEAAAAAAAAAAAAAAQARMSFBUf/aAAgBAQABPxBCgKiWCXz0+iVAVscIFMU6Qb4hUHP/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Gatsby Banner",
          "title": "Gatsby Banner",
          "src": "/static/91e26c134d6f238230e74165244995b8/b17f8/featured-image.jpg",
          "srcSet": ["/static/91e26c134d6f238230e74165244995b8/066f9/featured-image.jpg 400w", "/static/91e26c134d6f238230e74165244995b8/4b190/featured-image.jpg 800w", "/static/91e26c134d6f238230e74165244995b8/b17f8/featured-image.jpg 1600w", "/static/91e26c134d6f238230e74165244995b8/df51d/featured-image.jpg 2400w", "/static/91e26c134d6f238230e74165244995b8/8ec0a/featured-image.jpg 2560w"],
          "sizes": "(max-width: 1600px) 100vw, 1600px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <h2>{`The Pitch`}</h2>
    <p>{`The power of modern web technology at your fingertips. It doesn't get any better. Instead of using a CMS or WYSIWYG and being dependent upon those services, you're learning a skill that will scale with you and your career. In the time it takes to read this first paragraph, you could've installed Gatsby with React and a few plugins. Another 5 minutes or so you could deploy to github and create a github pages url and have a hosted site for free.`}</p>
    <p>{`The first time I picked up React - I failed miserably. I followed a lot of tutorials from some very great developers but the tutorials were out dated and the initilizations failed. Thats the problem with not being in the loop and understanding which dependencies are needed to successfully run you own local website for development. I did, however, have experience with static-site generators and I've used Jekyll for my previous portfolio but the platform didn't do what I wanted it to do because it basically used HTML and CSS. React does but it does it in such a better way. Think of the symbols in your sketch library and how you can reuse it over and over in your layouts and comps. React has that same principle called components. Its literally the greatest thing ever. `}</p>
    <p>{`Don't believe me? Check out this `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=8t0vNu2fCCM&t=10173s"
      }}>{`tutorial video`}</a>{` by Andrew Mead. He explains everything and teachs you how to build a Gatsby React site from scratch and deploy in a little over 4 hours.`}</p>
    <h2>{`Lets Dive In`}</h2>
    <p>{`Only way to really understand what I'm talking about is to dive right in, lets do it!`}</p>
    <p>{`Disclaimer: I'm on a Mac so I'm not sure what the steps are to recreate all of this in another OS.`}</p>
    <p>{`Lets crack open terminal - if you've never coded on your machine before, we'll need to get a few things setup.
If you're unsure - we can do a quick check in the terminal. `}</p>
    <p>{`Start by typing the following...`}</p>
    <pre><code parentName="pre" {...{}}>{`npm -v
node -v
`}</code></pre>
    <p>{`This will check if you have node installed on your machine. At the time of writing this post my latest versions are:`}</p>
    <pre><code parentName="pre" {...{}}>{`npm -v
6.14.4
node -v
v13.11.0
`}</code></pre>
    <p>{`If you don't have node installed - there are several ways to get it up and running on your machine. The easiest way I found was to download a package called `}<a parentName="p" {...{
        "href": "https://brew.sh/"
      }}>{`HomeBrew`}</a>{`.
Once you have that installed run the following command:`}</p>
    <pre><code parentName="pre" {...{}}>{`brew install node
`}</code></pre>
    <p>{`Ok! We're almost there! We can finally install Gatsby and get started! We'll want to install it globally so please run this command:`}</p>
    <pre><code parentName="pre" {...{}}>{`npm install -g gatsby-cli
`}</code></pre>
    <p>{`Alright! Phew! That was a lot of installing! We aren't done yet though. We now need to use a Gatsby command to install a project onto our machine. Before we do that, we'll want to navigate to the folder that we want to contain our project for me its located in `}<em parentName="p">{`Documents/Projects/Code`}</em>{` and to get there in our terminal we type:`}</p>
    <pre><code parentName="pre" {...{}}>{`cd documents/projects/code
`}</code></pre>
    <p>{`Before we start the next step, I encourage you to checkout all the starters on the Gatsby site. Starters are a quick way to well - get started. The `}<a parentName="p" {...{
        "href": "https://www.gatsbyjs.org/docs/starters/#official-starters"
      }}>{`official starters`}</a>{` include many options that already have SEO and other great things preinstalled. Whatever you choose the next step will be:`}</p>
    <pre><code parentName="pre" {...{}}>{`gatsby new [SITE_DIRECTORY_NAME] [URL_OF_STARTER_GITHUB_REPO]
`}</code></pre>
    <p>{`What this does is it creates a new Gatsby project in the directory of your choosing with either a default starter ( if you didn't specify one ) or the one you choose.`}</p>
    <p>{`For me, this is what the command looked like:`}</p>
    <pre><code parentName="pre" {...{}}>{`gatsby new gatsby-portfolio https://github.com/gatsbyjs/gatsby-starter-default
`}</code></pre>
    <p>{`Once that is done installing, navigate to your project folder in the terminal. In this case it would be:`}</p>
    <pre><code parentName="pre" {...{}}>{`cd gatsby-portfolio
`}</code></pre>
    <p>{`Lets get the server up and running with the following command:`}</p>
    <pre><code parentName="pre" {...{}}>{`gatsby develop
`}</code></pre>
    <p>{`Now open up your browser of choice and type the following into the URL bar `}<inlineCode parentName="p">{`localhost:8000`}</inlineCode>{` and there you have you have it! Its alive!`}</p>
    <h2>{`Now What?`}</h2>
    <p>{`Now I'm going to take a break but I encourage you to look through the doucmentation on the Gatsby site and follow the tutorials there or from the video above. I'll post another tutorial in the coming days - nothing else to do since we're all locked in right?`}</p>
    <h3>{`Stay Tuned and Thanks for Reading!`}</h3>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      